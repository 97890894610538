import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { TfiNewWindow } from 'react-icons/tfi'
import { IoMdArrowRoundBack } from 'react-icons/io'
import LoaderImg from '../../../../assets/loader.gif'
import { NotificationManager } from 'react-notifications'
import { useHistory } from 'react-router-dom'

import './Customer.scss'
import { axiosCalls } from '../../../../components/_api'
import Pagination from '../../../../components/Pagination/Pagination'
import Payment from './Payment'

function Customer() {
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useState()
  const history = useHistory()
  const [showSwitch, setShowSwitch] = useState(false)

  const fetchUser = async () => {
    setLoading(true)
    const res = await axiosCalls(`user/view/${id}`, 'GET')
    if (res) {
      if (res?.er) {
        NotificationManager.error('Error', res.er.message)
        setLoading(false)
        setUserData()
        return
      }
      setUserData(res.data)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchUser()
  }, [])

  ///Bookings
  const [bookings, setBookings] = useState()
  const [bookingsLoading, setBookingsLoading] = useState(true)

  const fetchBookings = async (page) => {
    const res = await axiosCalls(
      `admin/bookings?userId=${id}&page=${page}&limit=10`,
      'GET'
    )
    if (res) {
      if (res?.er) {
        NotificationManager.error('Error', res.er.message)
        setBookingsLoading(false)
        setBookings()
        return
      }
      console.log(res.data)

      setBookings(res.data)
      setBookingsLoading(false)
    }
  }
  useEffect(() => {
    fetchBookings(1)
  }, [])

  const ShowDetails = (id) => {
    // const findDetail = zoneList.docs.find((item) => item._id === id)
    // sessionStorage.setItem('pageDetail', JSON.stringify(findDetail))
    history.push(`/admin/booking/${id}`)
  }

  return loading ? (
    <div className='loadings'>
      <img src={LoaderImg} alt='' />
    </div>
  ) : (
    <div className='customer'>
      {showSwitch && (
        <Payment
          userData={userData}
          fetchUser={fetchUser}
          setShowSwitch={setShowSwitch}
        />
      )}
      <div className='top'>
        <div className='flex items-center'>
          <IoMdArrowRoundBack
            onClick={() => {
              history.goBack()
            }}
          />
          <h2
            onClick={() => {
              history.goBack()
            }}
          >
            Customer Details
          </h2>
        </div>
      </div>
      {userData ? (
        <div className='customer-wrap flex justify-between'>
          <div className='one'>
            <div className='placed-card'>
              <p>Total Charge</p>
              <h2>NGN{userData.totalCharge.toLocaleString()}</h2>
            </div>
            <div className='placed-card'>
              <p>Total Bookings</p>
              <h2>{userData.totalBookings}</h2>
            </div>
            <div className='details'>
              <div className='details-card-long'>
                <p>Full Name</p>
                <h3>
                  {userData.firstname} {userData.lastname}
                </h3>
              </div>
              <div className='flex justify-between'>
                <div className='details-card'>
                  <p>Username</p>
                  <h3>{userData.username}</h3>
                </div>
                <div className='details-card'>
                  <p>Phone number</p>
                  <h3>{userData.phonenumber}</h3>
                </div>
              </div>
              <div className='details-card-long'>
                <p>Email address</p>
                <h3>{userData.email}</h3>
              </div>
              <div className='flex justify-between'>
                <div className='details-card'>
                  <p>City</p>
                  <h3>{userData.city}</h3>
                </div>
                <div className='details-card'>
                  <p>State</p>
                  <h3>{userData.state}</h3>
                </div>
              </div>
              <div className='details-card-long'>
                <p>Address</p>
                <h3>{userData.address}</h3>
              </div>
              <div className='details-card-long'>
                <p>Payment Status</p>
                <h3>{userData.turnoff_payment ? 'OFF' : 'ON'}</h3>
                <button onClick={() => setShowSwitch(true)}>switch</button>
              </div>
            </div>
          </div>
          <div className='two'>
            <div className='two-wrapper'>
              <div className='wrap-top'>
                <h4>Booking List</h4>
              </div>
              <div className='home_tables'>
                <div className='left' style={{ minHeight: '60vh' }}>
                  {bookingsLoading ? (
                    <div className='loadings'>
                      <img src={LoaderImg} alt='' />
                    </div>
                  ) : (
                    <>
                      {bookings.docs.length > 0 ? (
                        <table>
                          <thead>
                            <tr>
                              <th>S/N</th>
                              <th>Date/Time</th>
                              <th>Value</th>
                              <th>Shipment Type</th>
                              <th>Tracking ID</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {bookings.docs.map((item, i) => (
                              <tr key={item._id}>
                                <td>{i + 1}</td>
                                <td className='count'>
                                  {new Date(item.createdAt).toLocaleString()}
                                </td>
                                <td className='count'>
                                  NGN {item.charge.toLocaleString()}
                                </td>
                                <td className='count'>{item.shipment_type}</td>
                                <td>{item.shipmentMeta.trackingId}</td>
                                <td className='icons'>
                                  <TfiNewWindow
                                    className='put'
                                    title='edit zone rate'
                                    onClick={() => ShowDetails(item._id)}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h1>No Bookings To Show</h1>
                      )}
                    </>
                  )}
                </div>
              </div>
              {!bookingsLoading && (
                <Pagination
                  className='pagination-bar'
                  currentPage={bookings.page}
                  totalCount={bookings.totalPages}
                  pageSize={bookings.limit}
                  onPageChange={(page) => {
                    fetchBookings(page)
                  }}
                />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>No User data to show</div>
      )}
    </div>
  )
}

export default Customer
