import React from 'react'
import './Customer.scss'
import { useParams } from 'react-router-dom'
import { axiosCalls } from '../../../../components/_api'
import { hideLoader, showLoader } from '../../../../components/loader'
import { NotificationManager } from 'react-notifications'

function Payment({ userData, fetchUser, setShowSwitch }) {
  const { id } = useParams()
  const createAdmin = async () => {
    const data = {
      userId: id,
      status: userData.turnoff_payment ? false : true,
    }
    showLoader()
    const res = await axiosCalls('user/payment-flag', 'PUT', data)
    if (res?.er) {
      NotificationManager.error('Error', res.er.message)
      hideLoader()
      return
    }
    NotificationManager.success('Successs', res.message)
    fetchUser()
    hideLoader()
    setShowSwitch(false)
  }
  return (
    <div className='payment-modal'>
      <div className='modal-body'>
        <h1>
          Are you sure you want to turn{' '}
          {userData.turnoff_payment ? 'on' : 'off'} payment for this user?
        </h1>
        <p>
          Note that this user would {userData.turnoff_payment ? '' : 'not'} be
          required to pay during booking.
        </p>
        <div className='flex buttons'>
          <button>Cancel</button>
          <button className='proceed' onClick={createAdmin}>
            Proceed
          </button>
        </div>
      </div>
    </div>
  )
}

export default Payment
