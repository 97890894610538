import React, { useEffect, useState } from 'react'
import track from '../../assets/Track delivery.png'
import { useHistory, useParams } from 'react-router-dom'
import mapframe from '../../assets/mapframe.png'
import group from '../../assets/group.png'
import copy from '../../assets/copy.png'
import sshot from '../../assets/sshot.png'
import arrowvec from '../../assets/arrowvec.png'
import LoaderImg from '../../assets/loader.gif'
import moment from 'moment'

import './step.css'
import { axiosCalls } from '../../components/_api'
export default function Track() {
  const history = useHistory()
  const { id } = useParams()
  const [trackingData, setTrackingData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    trackPackage()
  }, [])

  const trackPackage = async () => {
    const res = await axiosCalls(`tracking/single?id=${id}`, 'GET')
    if (res) {
      if (res.er) {
        return
      }
      setTrackingData(res.data.shipments[0])
      setLoading(false)
    }
  }
  return loading ? (
    <div className='loadings'>
      <img src={LoaderImg} alt='' />
    </div>
  ) : (
    <div>
      <div className='where-left-arrow'>
        <img
          src={arrowvec}
          onClick={() => {
            history.goBack()
          }}
        />
      </div>
      <img src={mapframe} style={{ width: '100%', position: 'absolute' }}></img>
      <div
        style={{
          background: '#fff',
          position: 'absolute',
          margin: '5% 0 0 5%',
          width: '40%',
          height: ' 90vh',
          overflowY: 'auto',
        }}
      >
        <div style={{ width: '80%', margin: 'auto', marginTop: '10%' }}>
          <p style={{ fontSize: '18px' }}>Tracking Number</p>
          <p>
            <img src={group} />
            <b style={{ fontSize: '18px' }}>#{id}</b>
          </p>
          <p style={{ fontSize: '18px' }}>Package Status</p>
          {trackingData.events.map((item, i) => (
            <div class='step step-active' key={i + 1}>
              <div>
                <div class='circle'>{i + 1}</div>
              </div>
              <div>
                <div class='title'>{item.description}</div>
                <div class='caption'>
                  {moment(
                    `${item.date} ${item.time}`,
                    'YYYY-MM-DD HH:mm:ss'
                  ).format('MMMM Do YYYY h:mm A')}
                </div>
              </div>
            </div>
          ))}
          {/* <button
            className='where-address-button'
            style={{ top: '20%' }}
            style={{ backgroundColor: '#000' }}
          >
            View Package Info
          </button> */}
        </div>
      </div>
    </div>
  )
}
