import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { AiFillCloseCircle, AiFillDelete } from 'react-icons/ai'
import { hideLoader, showLoader } from '../../../../components/loader'
import { axiosCalls } from '../../../../components/_api'
import { NotificationManager } from 'react-notifications'
import { MdOutlineOpenInNew } from 'react-icons/md'
import LoaderImg from '../../../../assets/loader.gif'

function Customers() {
  const [loading, setLoading] = useState(true)
  const [adminList, setAdminList] = useState()
  const [selectedAdmin, setSelectedAdmin] = useState({ name: '' })
  const history = useHistory()
  //Fetching of Admin users >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const fetchAdmin = async () => {
    const res = await axiosCalls('user/list?limit=20&page=1', 'GET')
    if (res) {
      if (res?.er) {
        NotificationManager.error('Error', res.er.message)
        return
      }
      if (isMounted.current) {
        // check if the component is still mounted
        setAdminList(res.data)
        setLoading(false)
      }
    }
  }
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    fetchAdmin()
    return () => {
      isMounted.current = false // cleanup function to cancel the request if the component unmounts
    }
  }, [])

  //Deleting of Admin user >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const selectAdmin = (id) => {
    history.push(`/admin/customer/${id}`)
  }

  return (
    <div className='full-zone'>
      <div className='home_pagex'>
        <div className='home_tag'>
          <h1>Customers</h1>
        </div>
        <div className='home_tables'>
          <div className='left' style={{ minHeight: '60vh' }}>
            {loading ? (
              <div className='loadings'>
                <img src={LoaderImg} alt='' />
              </div>
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Full Name</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {adminList.map((data, i) => (
                    <tr key={data._id}>
                      <td>{i + 1}</td>
                      <td>
                        {data.firstname} {data.lastname}
                      </td>
                      <td className='count'>{data.username}</td>
                      <td className='count'>{data.email}</td>
                      <td className='icons dif'>
                        <MdOutlineOpenInNew
                          onClick={() => selectAdmin(data._id)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Customers
